:root {
  --primary: #26a69a;
  --text: #004d40;
  --bodyPadding: 8rem 5rem 2rem;
}

#root {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  height: 100vh;
}

/* Landing page header */
/* Color of the links BEFORE scroll */
.navbar-scroll .nav-link,
.navbar-scroll .navbar-toggler-icon,
.navbar-scroll .navbar-brand {
  color: #fff;
}

/* Color of the links AFTER scroll */
.navbar-scrolled .nav-link,
.navbar-scrolled .navbar-toggler-icon,
.navbar-scrolled .navbar-brand {
  color: #fff;
}

/* Color of the navbar AFTER scroll */
.navbar-scroll,
.navbar-scrolled {
  background-color: #fff;
}

.mask-custom {
  backdrop-filter: blur(5px);
  background-color: #fff;
}

.navbar-brand {
  font-size: 1.75rem;
  letter-spacing: 3px;
}

.liner {
  border-left: black 1px solid;
  margin: 0rem 4rem;
}

.private_header {
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

/* QUICK LINKS */
.quicklinks {
  padding: 2rem 3rem 0.5rem;
  background-color: #e0f2f1;
}

.quicklinks_first {
  display: grid;
  grid-template-columns: 20% 1fr 20%;
}

.company_info {
  padding: 4rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.company_info h4 {
  /* mix-blend-mode: darken; */
  text-shadow: 1px 1px 1px rgb(0, 0, 0), 0 1px 1px rgb(0, 0, 0);
}

.quick_logo {
  margin: 1rem;
}

.quick_info {
  padding: 4rem 1rem 1rem;
  text-align: center;
}

.quick_info h3 {
  color: var(--text);
}

.quick_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quick_links a {
  text-decoration: none;
  color: var(--text);
  font-weight: bold;
}

.quick_links a:hover {
  text-decoration: none;
  color: #00897b;
  font-weight: bold;
}

.button-1 {
  align-items: center;
  appearance: none;
  background-color: var(--text);
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #f6f9fe;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: larger;
  font-weight: bolder;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 40%;
  will-change: transform, opacity;
  z-index: 0;
}

.button-1:hover {
  background: #f6f9fe;
  color: var(--text);
}

.fonts a:hover {
  color: #00897b;
}

.sos {
  display: flex;
  flex-direction: row-reverse;
}

.quicklinks_bottom {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.quicklink_foot1 {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.quicklink_foot2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Footer */
.footer {
  background-color: black;
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 2rem;
  color: white;
}

.privacy {
  display: flex;
  flex-direction: row-reverse;
  width: 75%;
}

.privacy a {
  text-decoration: none;
  color: white;
}

.privacy a:hover {
  color: white;
}

.back {
  cursor: pointer;
  /* padding: 0.5rem; */
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;
}

.back:hover {
  background-color: #eceff1;
}

p {
  font-size: large;
}

/* ADVERTS */
.advert_body {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.advert_image img {
  width: 100%;
}

.advert_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.advert_content h1 {
  font-weight: bold;
  color: #004d40;
}

/* loader styling */
.ring {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: transparent;
  /* border:3px solid #3c3c3c; */
  border-radius: 50%;
  text-align: center;
  line-height: 150px;
  font-family: sans-serif;
  font-size: 20px;
  /* color:#fff000; */
  letter-spacing: 4px;
  text-transform: uppercase;
  /* text-shadow:0 0 10px #fff000; */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background-image: url("https://res.cloudinary.com/dex0mkckw/image/upload/v1719013196/static%20files/logo_hxbg7j.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.ring:before {
  content: "";
  display: none;
  position: absolute;
  top: -3px;
  left: -3px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid #004d40;
  border-right: 3px solid #004d40;
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}
.ring span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}
.ring span:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #fff;
  top: -6px;
  right: -8px;
  box-shadow: 0 2px 20px #004d40;
}
@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

/* post loader */
.main-item {
  padding: auto;
  background-color: #fff;
  width: 100%;
}

.background-masker {
  background-color: #fff;
  position: absolute;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  background-size: 800px 104px;
  height: 70px;
  position: relative;
}

.static-background {
  background-color: #e7eaec;
  background-size: 800px 104px;
  height: 70px;
  position: relative;
  margin-bottom: 20px;
}

.shared-dom {
  width: 100%;
  height: 110px;
}
.sub-rect {
  border-radius: 100%;
  width: 100%;
  height: 70px;
  float: left;
  margin: 20px 20px 20px 0;
}
.pure-background {
  background-color: #eee;
}

.css-dom:empty {
  width: 100%;
  height: 220px;
  border-radius: 6px;
  box-shadow: 0 10px 45px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;

  background-image: radial-gradient(circle 16px, lightgray 99%, transparent 0),
    linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray),
    linear-gradient(lightgray, lightgray), linear-gradient(lightgray, lightgray),
    linear-gradient(#fff, #fff);

  background-size: 32px 32px, 200px 32px, 180px 32px, 230px 16px, 100% 40px,
    280px 100%;

  background-position: 24px 30px, 66px 30px, 24px 90px, 24px 142px, 0 180px, 0 0;
}

.loade {
  width: 30px;
  height: 30px;
  border: 5px solid #004d40;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.load {
  width: 30px;
  height: 30px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
