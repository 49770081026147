/* Chatrooms */
.chatrooms {
  padding: 4rem 18rem 0rem;
}

.chat {
  margin-top: 1rem;
}

.chat p {
  font-size: small;
}

.chat_list {
  padding: 1rem 0rem;
}

.chat_image {
  width: 1rem;
  height: 1rem;
  border-radius: 1.5rem;
  margin-right: 0.5rem;
  overflow: hidden;
  background-color: #004d40;
}

.chat_image img {
  height: 100%;
  width: 100%;
}

.chat_item {
  display: flex;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.chat_title {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.chat_title h6 {
  margin: 0rem;
  text-transform: capitalize;
}

.chat_title p {
  font-size: small;
  margin: 0px;
}

.single_chat {
  padding: 0rem 7rem 0rem;
  /* height: 91vh; */
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  display: flex;
  flex-direction: column-reverse;
}

.single_chat::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.other_room_user {
  display: flex;
  width: 70%;
  margin: 0rem 0rem;
}

.current_room_user {
  display: grid;
  grid-template-columns: 2% 1fr;
  padding-left: 1rem;
}

.room_user_image {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  overflow: hidden;
}

.room_user_image img {
  width: 100%;
  height: 100%;
}

.room_chat_body {
  background-color: #004d40;
  color: #fff;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 0.3rem 1rem 0.5rem;
  border-radius: 0rem 1rem 1rem 1rem;
  margin-top: 0.5rem;
}

.room_user_body {
  padding: 0rem 1rem;
  margin: 0rem;
}

.room_user_body h6 {
  text-transform: capitalize;
  margin-bottom: 0rem;
  margin-right: 1rem;
}

.room_user_body p{
  margin-bottom: 5px;
}

.room_user_body pre {
  font-family: "Poppins", sans-serif;
  font-size: medium;
  font-style: normal;
  line-height: 1.6;
}

.new_chatroom_button {
  align-items: center;
  appearance: none;
  background-color: #004d40;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  float: right;
  font-size: larger;
  font-weight: bolder;
  height: max-content;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 0.2rem 1rem;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.chatroom_title {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* width: 100%; */
  padding: 0.5rem 0.5rem;
  background-color: #abb8b8;
}

.chatroom_form input {
  border: none;
  outline: none;
  border-bottom: #004d40 1px solid;
}

.chatroom_form input:focus {
  background-color: #e0f2f1;
}

.account_button {
  text-align: end;
  width: 45%;
  display: none;
  align-items: center;
  justify-content: end;
}

.account_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.account_body h1 {
  font-weight: bold;
}

.account_body p {
  display: flex;
  align-items: center;
}

.account_table td,
th {
  padding: 1rem 0.5rem;
}

.account_buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.add_friend {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;
}

.add_friend_input input {
  border: none;
  border-bottom: #004d40 1px solid;
  width: 100%;
}

.add_friend_input input:focus {
  outline: none;
  background-color: #e0f2f1;
}

.add_friend_input {
  width: 60%;
  padding: 0rem 1rem;
}

.result_user_image {
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-right: 0.2rem;
}

.result_user_image img {
  height: 100%;
  width: 100%;
}

.search_result_item {
  display: grid;
  grid-template-columns: 8% 1fr 15%;
  margin: 0.7rem 0rem;
  width: 100%;
  text-transform: capitalize;
}

.search_result {
  margin-top: 1rem;
  width: 60%;
  padding: 0rem 0.5rem;
}

.add_button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #004d40;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  float: right;
  font-size: small;
  font-weight: bolder;
  height: max-content;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  margin: auto;
  max-width: 100%;
  overflow: visible;
  padding: 0.2rem 1rem;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.wallet_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.wallet_form input {
  margin: 1rem 0px;
  border: none;
  outline: none;
  border-bottom: #004d40 1px solid;
}

.wallet_form input:focus {
  background-color: #e0f2f1;
}

@media screen and (max-width: 1200px) {
  .chatrooms {
    padding: 3rem 0.5rem 0rem;
  }

  .chat_list {
    padding: 0rem;
  }

  .chat_image {
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    margin-right: 0.5rem;
    overflow: hidden;
    background-color: #004d40;
  }

  .chat_item {
    display: grid;
    grid-template-columns: 5% 1fr;
    padding: 0.5rem 0.9rem 0.5rem 0rem;
    cursor: pointer;
  }

  .chat_title h5,
  p {
    margin: 0rem;
    overflow: hidden;
  }

  .chat_title p {
    font-size: medium;
  }

  .single_chat {
    padding: 0rem;
    overflow-y: auto;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none;
  }

  .single_chat::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .chatroom_title h4 {
    display: flex;
    align-items: center;
  }

  .other_room_user {
    display: grid;
    grid-template-columns: 9% 1fr;
    margin: 0.1rem 0rem 0rem;
    font-size: small;
  }

  .current_room_user {
    display: grid;
    grid-template-columns: 5% 1fr;
  }

  .room_user_image {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    overflow: hidden;
  }

  .room_chat_body {
    background-color: #004d40;
    color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 0.4rem 1rem;
    border-radius: 0rem 1rem 1rem 1rem;
    margin-top: 0.2rem;
    width: max-content;
  }

  .room_user_body {
    padding: 0rem 1rem;
    margin: 0rem;
  }

  .room_user_body h6 {
    text-transform: capitalize;
    margin-right: 1rem;
  }

  .room_user_body pre {
    font-family: "Poppins", sans-serif;
    font-size: medium;
    font-style: normal;
    line-height: 1.6;
  }

  .add_friend {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0.5rem;
  }

  .add_friend_input input {
    border: none;
    border-bottom: #004d40 1px solid;
    width: 100%;
  }

  .add_friend_input input:focus {
    outline: none;
    background-color: #e0f2f1;
  }

  .add_friend_input {
    width: 100%;
    padding: 0rem 1rem;
  }

  .result_user_image {
    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    overflow: hidden;
    margin-right: 0.2rem;
  }

  .result_user_image img {
    height: 100%;
    width: 100%;
  }

  .search_result_item {
    display: grid;
    grid-template-columns: 13% 1fr 15%;
    margin: 0.7rem 0rem;
    width: 100%;
  }

  .search_result {
    margin-top: 1rem;
    width: 100%;
    padding: 0rem 0.5rem;
  }
}
