/* LANDING PAGE */
.home_banner {
  display: grid;
  grid-template-columns: 40% 1fr;
  padding: 4rem 0rem;
}

.banner_display_image img {
  width: 100%;
}

.banner_display {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 2rem;
}

.home_socials {
  display: grid;
  grid-template-columns: 1fr 30%;
  width: 100%;
  padding-left: 2rem;
}

.home_social {
  display: flex;
  justify-content: center;
}

.home_social a {
  margin: 1rem;
  color: var(--text);
}

.home_social a:hover {
  color: var(--text);
}

.home_logo {
  padding-left: 3rem;
}

.title {
  display: flex;
  flex-direction: column;
  padding-left: 2.5rem;
}

.title_image {
  display: flex;
  align-items: center;
  padding: 0rem 0rem 2rem 0rem;
}

.title p {
  font-size: larger;
  color: #004d40;
  font-weight: bold;
}

.title h1 {
  color: #012c25;
  text-shadow: 1px 1px 1px rgb(0, 0, 0), 0 1px 1px rgb(0, 0, 0);
  font-weight: bold;
  font-size: 2.8rem;
}

.title small {
  margin: 0rem 0rem 1rem;
  font-weight: bold;
}

.business_button {
  align-items: center;
  appearance: none;
  background-color: #004d40;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: larger;
  font-weight: bolder;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.business_button:hover {
  background: #fff;
  color: #004d40;
}

.business_button:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
    0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.business_button:focus {
  outline: none;
  border: 2px solid #f6f9fe;
}

.business_button:not(:disabled) {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.business_button:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
    rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
}

.business_button:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.business_button:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
    rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
}

.business_button:disabled {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
    rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
}

.links {
  padding: 2rem;
}

.links a {
  color: #004d40;
  font-weight: bold;
  text-decoration: none;
  margin: 1rem 1rem 0rem;
}

.links a:hover {
  color: #004d40;
}

.pharmapool_intro {
  padding: 2rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pharmapool_intro h6 {
  text-align: justify;
}

.info {
  padding: 2rem 5rem 2rem;
}

.info h1 {
  text-align: center;
  color: var(--text);
  font-size: 3rem;
  font-weight: bolder;
}

.home_services {
  padding-top: 0rem;
}

.home_service {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0rem;
}

.home_serve {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0rem;
}

.home_servic {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0rem;
}

.home_serv {
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 1rem 0rem;
}

.servic_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.servic_img img {
  width: 50%;
}

.service_img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.service_img img {
  width: 100%;
}

.service_details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem;
}

.service_details h3 {
  color: var(--text);
  font-weight: bold;
  font-size: xx-large;
  padding-bottom: 1rem;
}

.service_details p {
  word-wrap: normal;
}

.home_footer {
  padding: 0rem 5rem;
  font-size: large;
  margin-bottom: 2rem;
}

.home_footer b {
  color: var(--text);
  font-style: italic;
}

.home_footer a {
  text-decoration: none;
}

.medicine_request{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
}

.home_reason {
  padding: 6rem 5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #004d40;
  color: #fff;
}

.home_reason h2 {
  color: white;
}

.reasons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  padding: 8rem 0rem 0rem;
  width: 100%;
}

.join_us {
  padding: 0rem 8rem 2rem;
}

.join {
  border: #004d40 2px solid;
  border-radius: 10px;
  padding: 2rem 3rem;
}

.join h1 {
  color: #004d40;
  font-weight: bold;
}

.join div {
  display: flex;
}

.certified {
  text-align: center;
  padding: 8rem 1rem;
}

.certified h1 {
  font-size: 4rem;
  font-weight: bold;
  color: #004d40;
}

.how_it_works_img {
  margin: 0rem 2rem 2rem;
}

.how_it_works_img img {
  width: 100%;
}

.how_it_works ul {
  list-style-type: disc;
}

.how_it_works li {
  font-weight: bold;
  margin: 1rem;
}

/* emailjs */
.home_form {
  margin-bottom: 2rem;
}

.home_form fieldset {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: auto;
  padding: 2rem;
  font-weight: bold;
}

.home_form legend {
  font-size: 3rem;
  color: #004d40;
}

.home_form input {
  border: none;
  border-bottom: #004d40 solid 1px;
  margin-bottom: 1rem;
  height: 2rem;
  padding: 0.1rem;
  outline: none;
}

.home_form input:focus {
  outline: none;
  border: none;
  border-bottom: #004d40 solid 1px;
  background-color: #e0f2f1;
  margin-bottom: 1rem;
  height: 2rem;
  padding: 0.1rem;
}

.home_form textarea {
  border: none;
  border-bottom: #004d40 solid 1px;
  margin-bottom: 1rem;
  padding: 0.1rem;
}

.home_form textarea:focus {
  outline: none;
  border: none;
  border-bottom: #004d40 solid 1px;
  background-color: #e0f2f1;
  margin-bottom: 1rem;
  padding: 0.1rem;
}

.form-but {
  display: flex;
  flex-direction: row-reverse;
}

.form-but button {
  background-color: var(--text);
  color: white;
  border: none;
  width: 10rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
}

.message a {
  text-decoration: none;
  color: black;
}

.success {
  background-color: #004d40;
  color: white;
  display: flex;
  padding: 2rem;
}

.success p {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  /* LANDING PAGE */
  .home_banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0rem;
  }

  .banner_display_image {
    width: 100%;
  }

  .banner_display_image img {
    width: 100%;
  }

  .banner_display {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 1rem;
    margin-top: 1rem;
  }

  .home_socials {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    padding-left: 1rem;
  }

  .home_social {
    display: flex;
    justify-content: center;
  }

  .home_social a {
    margin: 0.5rem;
    color: var(--text);
  }

  .home_social a:hover {
    color: var(--text);
  }

  .home_logo {
    padding-left: 1rem;
  }

  .title {
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  .title_image {
    display: flex;
    align-items: center;
    padding: 0rem 0rem 1rem 0rem;
  }

  .title p {
    font-size: larger;
    color: #004d40;
    font-weight: bold;
  }

  .title h1 {
    color: #012c25;
    text-shadow: 1px 1px 1px rgb(0, 0, 0), 0 1px 1px rgb(0, 0, 0);
    font-weight: bold;
    font-size: 2.2rem;
  }

  .title small {
    margin: 0rem 0rem 1rem;
    font-weight: bold;
  }

  .business_button {
    align-items: center;
    appearance: none;
    background-color: #004d40;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
      rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-size: larger;
    font-weight: bolder;
    height: 48px;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: max-content;
    will-change: transform, opacity;
    z-index: 0;
  }

  .business_button:hover {
    background: #fff;
    color: #004d40;
  }

  .business_button:active {
    box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%),
      0 8px 12px 6px rgb(60 64 67 / 15%);
    outline: none;
  }

  .business_button:focus {
    outline: none;
    border: 2px solid #f6f9fe;
  }

  .business_button:not(:disabled) {
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  }

  .business_button:not(:disabled):hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0 2px 3px 0,
      rgba(60, 64, 67, 0.15) 0 6px 10px 4px;
  }

  .business_button:not(:disabled):focus {
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  }

  .business_button:not(:disabled):active {
    box-shadow: rgba(60, 64, 67, 0.3) 0 4px 4px 0,
      rgba(60, 64, 67, 0.15) 0 8px 12px 6px;
  }

  .business_button:disabled {
    box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
      rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  }

  .links {
    padding: 0rem 0rem 0rem 1vw;
    display: flex;
    align-items: center;
  }

  .links a {
    color: #004d40;
    font-weight: bold;
    text-decoration: none;
    margin: 1rem auto 0rem;
    text-align: center;
  }

  .links a:hover {
    color: #004d40;
  }

  .pharmapool_intro {
    padding: 3rem 1rem;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .pharmapool_intro h6 {
    text-align: center;
    font-size: large;
    font-weight: normal;
  }

  .intro_image img {
    width: 100%;
  }

  .info {
    padding: 0rem;
  }

  .info h1 {
    text-align: center;
    color: var(--text);
    font-size: 3rem;
    font-weight: bolder;
  }

  .home_services {
    padding-top: 0rem;
  }

  .home_service {
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem;
    height: max-content;
  }

  .home_serve {
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column-reverse;
    margin: 1rem 0rem;
    height: max-content;
  }

  .home_servic {
    padding: 1rem;
    display: flex;
    flex-direction: column-reverse;
    margin: 1rem 0rem;
  }

  .home_serv {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin: 1rem 0rem;
  }

  .servic_img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .servic_img img {
    width: 100%;
  }

  .service_img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .service_img img {
    width: 100%;
  }

  .service_details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 0.5rem;
  }

  .service_details h3 {
    color: var(--text);
    font-weight: bold;
    font-size: xx-large;
    padding-bottom: 1rem;
  }

  .service_details p {
    font-size: large;
  }

  .home_footer {
    padding: 0rem 1rem;
    font-size: large;
    margin-bottom: 2rem;
  }

  .home_footer b {
    color: var(--text);
    font-style: italic;
  }

  .home_footer a {
    text-decoration: none;
  }

  .home_reason {
    padding: 4rem 1rem;
    text-align: center;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #004d40;
    color: #fff;
  }

  .home_reason h2 {
    color: white;
  }

  .reasons {
    display: flex;
    flex-direction: column;
    padding: 2rem 0rem 0rem;
    width: 100%;
  }

  .reason {
    margin: 1rem 0rem;
  }

  .join_us {
    padding: 0rem 1rem 2rem;
  }

  .join {
    border: #004d40 2px solid;
    border-radius: 10px;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
  }

  .join h3 {
    color: #004d40;
    font-weight: bold;
  }

  .join div {
    display: flex;
    flex-direction: column;
  }

  .join div p {
    margin-bottom: 1rem;
  }

  .certified {
    text-align: center;
    padding: 8rem 1rem;
  }

  .certified h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #004d40;
  }

  .how_it_works_img {
    margin: 0rem 1rem 2rem;
  }

  .how_it_works_img img {
    width: 100%;
  }

  .how_it_works ul {
    list-style-type: disc;
  }

  .how_it_works li {
    font-weight: bold;
    margin: 1rem;
  }

  .home_form fieldset {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto;
    padding: 2rem 1rem;
  }

  .home_form legend {
    font-size: 2rem;
    color: #004d40;
  }
}
