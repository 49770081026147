/* Contact */
.contact {
  padding: var(--bodyPadding);
}

.contact h1 {
  color: #004d40;
}

.con-con {
  display: grid;
  grid-template-columns: 40% 1fr;
  padding-top: 4rem;
}

.form {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
}

.form input {
  border: none;
  border-bottom: #004d40 solid 1px;
  margin-bottom: 1rem;
  height: 2rem;
  padding: 0.1rem;
  outline: none;
}

.form input:focus {
  outline: none;
  border: none;
  border-bottom: #004d40 solid 1px;
  background-color: #e0f2f1;
  margin-bottom: 1rem;
  height: 2rem;
  padding: 0.1rem;
}

.form textarea {
  border: none;
  border-bottom: #004d40 solid 1px;
  margin-bottom: 1rem;
  padding: 0.1rem;
}

.form textarea:focus {
  outline: none;
  border: none;
  border-bottom: #004d40 solid 1px;
  background-color: #e0f2f1;
  margin-bottom: 1rem;
  padding: 0.1rem;
}

.mm {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
  grid-gap: 1rem;
}

.form-but {
  display: flex;
  flex-direction: row-reverse;
}

.form-but button {
  background-color: var(--text);
  color: white;
  border: none;
  width: 10rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
}

.socials a {
  color: var(--text);
  margin: 0rem 0.3rem;
  font-size: larger;
}

.message a {
  text-decoration: none;
  color: black;
}

.success {
  background-color: #004d40;
  color: white;
  display: flex;
  padding: 2rem;
}

.success p {
  width: 100%;
}

@media screen and (max-width: 1200px) {
  /* Contact */
  .contact {
    padding: 5rem 1rem;
  }

  .contact h1 {
    color: #004d40;
  }

  .con-con {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 1rem;
  }

  .form {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .form input {
    border: none;
    border-bottom: #004d40 solid 1px;
    margin-bottom: 1rem;
    height: 2rem;
    padding: 0.1rem;
    outline: none;
  }

  .form input:focus {
    outline: none;
    border: none;
    border-bottom: #004d40 solid 1px;
    background-color: #e0f2f1;
    margin-bottom: 1rem;
    height: 2rem;
    padding: 0.1rem;
  }

  .form textarea {
    border: none;
    border-bottom: #004d40 solid 1px;
    margin-bottom: 1rem;
    padding: 0.1rem;
  }

  .form textarea:focus {
    outline: none;
    border: none;
    border-bottom: #004d40 solid 1px;
    background-color: #e0f2f1;
    margin-bottom: 1rem;
    padding: 0.1rem;
  }

  .mm {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(40%, 1fr));
    grid-gap: 1rem;
  }

  .form-but {
    display: flex;
    flex-direction: row-reverse;
  }

  .form-but button {
    background-color: var(--text);
    color: white;
    border: none;
    width: 10rem;
    height: 2.5rem;
    border-radius: 0.5rem;
    font-weight: bold;
  }

  .socials a {
    color: var(--text);
    margin: 0rem 0.3rem;
    font-size: larger;
  }

  .message a {
    text-decoration: none;
    color: black;
  }
}
