.admin_users {
  padding: 5rem 1rem 1rem;
  display: grid;
  grid-template-columns: 30% 1fr;
  width: 100vw;
}

.delete_button {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.delete_button button {
  border: none;
  background-color: white;
  color: red;
  font-weight: bold;
}

.user_item_profile_img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2rem;
  overflow: hidden;
  margin-right: 0.5rem;
}

.user_item_profile_img img {
  width: 100%;
  height: 100%;
}

.user_item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.user_item:hover {
  background-color: #e0f2f1;
}

.user_item p {
  text-transform: capitalize;
}

.user_details_banner_img {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  overflow: hidden;
  margin-right: 0.5rem;
}

.user_details_banner_img img {
  width: 100%;
  height: 100%;
}

.user_details_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_details_banner_detail {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_details {
  position: fixed;
  overflow-y: scroll;
  left: 32%;
  height: 90vh;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.users_list {
  position: fixed;
  overflow-y: scroll;
  height: 100vh;
  margin-top: 2rem;
  width: 30%;
}
