/* Chats */
.chats {
  padding: 4rem 18rem 0rem;
}

.chat {
  padding-top: 4rem;
  padding-right: 2rem;
}

.other_user {
  display: flex;
  width: 60%;
  margin: 0.5rem 0rem;
}

.chat_body {
  background-color: #004d40;
  color: #fff;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 0.3rem 1rem 0.5rem;
  border-radius: 0rem 1rem 1rem 1rem;
}

.user_body {
  background-color: #fff;
  color: #004d40;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  padding: 0.3rem 1rem 0.5rem;
  border-radius: 1rem 0rem 1rem 1rem;
}

.current_user {
  display: flex;
  flex-direction: row-reverse;
  margin: 0.5rem 0rem;
  padding-left: 40%;
}

.chat_user_image {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  margin-right: 0.5rem;
  overflow: hidden;
}

.chat_user_image img {
  width: 100%;
  height: 100%;
}

.chat_titl {
  display: flex;
  align-items: center;
}

.chat_titl h5 {
  margin-bottom: 0px;
}

.chat_header {
  padding: 0.5rem 8rem 0rem;
  display: grid;
  grid-template-columns: 2% 88% 10%;
  position: fixed;
  height: fit-content;
  background-color: #abb8b8;
  width: 100%;
}

.chat_header h3 {
  margin: 0rem;
}

.chat_input {
  position: fixed;
  /* height: 60px; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5%;
  padding: 0.5rem 8rem;
}

.chat_input textarea {
  border: #004d40 1px solid;
  /* border-radius: 5rem; */
  padding: 0.5rem 1rem;
  outline: #004d40;
  scrollbar-width: none;
  vertical-align: middle;
  background-color: #abb8b8;
}

.chat_input div {
  display: flex;
  flex-direction: column-reverse;
}

.chat_input button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-sizing: border-box;
  color: #004d40;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: larger;
  font-weight: bolder;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* width: 40%; */
  will-change: transform, opacity;
  z-index: 0;
}

.chat_imag {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.5rem;
  margin-right: 0.5rem;
  overflow: hidden;
}

.chat_imag img {
  width: 100%;
  height: 100%;
}

.chat_itm {
  display: grid;
  grid-template-columns: 5.5% 1fr;
  padding: 0.5rem 0.9rem 0.5rem 0rem;
  cursor: pointer;
}

.friend_image img {
  width: 100%;
  height: 100%;
}

.friend_image {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  overflow: hidden;
  margin-right: 0.3rem;
}

.friend h5 {
  text-transform: capitalize;
  margin: 0px;
}

.friend {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .chats {
    padding: 3rem 1rem 0rem;
  }

  .chat {
    padding-top: 4rem;
    padding-right: 0rem;
  }

  .other_user {
    display: flex;
    width: 90%;
    margin: 1rem 0rem;
    font-size: small;
  }

  .chat_body {
    background-color: #004d40;
    color: #fff;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 0.5rem;
    border-radius: 0rem 1rem 1rem 1rem;
  }

  .user_body {
    background-color: #fff;
    color: #004d40;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    padding: 0.5rem;
    border-radius: 1rem 0rem 1rem 1rem;
  }

  .current_user {
    display: flex;
    flex-direction: row-reverse;
    margin: 0.5rem 1rem;
    padding-left: 10%;
    font-size: small;
  }

  .chat_user_image {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
  }

  .chat_header {
    padding: 0rem 0rem 0.5rem 0rem;
    display: grid;
    grid-template-columns: 9% 1fr 15%;
    width: 100%;
    height: fit-content;
    background-color: #abb8b8;
  }

  .chat_header div {
    display: flex;
    align-items: center;
  }

  .chat_header h3 {
    margin: 0rem;
  }

  .chat_input {
    position: fixed;
    width: 100%;
    display: grid;
    grid-template-columns: 90% 10%;
    padding: 0.5rem;
  }

  .chat_imag {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.5rem;
    margin-right: 0.5rem;
    overflow: hidden;
  }

  .chat_itm {
    display: grid;
    grid-template-columns: 12% 1fr;
    padding: 0.5rem 0.9rem 0.5rem 0rem;
    cursor: pointer;
  }
}
