.admin_wallets {
  padding: 5rem 4rem 1rem;
  display: grid;
  grid-template-columns: 20% 1fr;
  width: 100vw;
}

.user_item_profile_img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2rem;
  overflow: hidden;
  margin-right: 0.5rem;
}

.user_item_profile_img img {
  width: 100%;
  height: 100%;
}

.user_item {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
}

.user_item:hover {
  background-color: #e0f2f1;
}

.user_item p {
  text-transform: capitalize;
}

.user_details_banner_img {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  overflow: hidden;
  margin-right: 0.5rem;
}

.user_details_banner_img img {
  width: 100%;
  height: 100%;
}

.user_details_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_details_banner_detail {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user_details {
  position: fixed;
  overflow-y: scroll;
  left: 32%;
  height: 90vh;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.users_list {
  position: fixed;
  overflow-y: scroll;
  height: 90vh;
  margin-top: 2rem;
  width: 30%;
}

.wallet_details{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 3rem;
}