.notifications {
  padding: 4rem 18rem 1rem;
}

.notification_list {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.notification {
  padding: 0.5rem;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  margin: 0.2rem;
  display: grid;
  grid-template-columns: 1fr 4%;
}

.notification h6 {
  text-transform: capitalize;
}

.notifications_header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #abb8b8;
  padding: 0.5rem;
}

.notifications_header button {
  align-items: center;
  appearance: none;
  background-color: #abb8b8;
  /* border-radius: 24px; */
  border-style: none;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0; */
  box-sizing: border-box;
  color: #004d40;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 0.5rem 0.5rem;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.notifications_header h5 {
  margin: 0rem;
  display: flex;
  align-items: center;
}

.notifications_header div {
  display: flex;
  flex-direction: row-reverse;
}

@media screen and (max-width: 1200px) {
  .notifications {
    padding: 3rem 0.5rem 1rem;
  }

  .notification_list {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .notification {
    padding: 0.5rem;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
      rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    margin: 0.2rem;
  }
}
