/* REQUESTS */
.requests {
  padding: 4rem 18rem 2rem;
}

.users_image {
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  overflow: hidden;
}

.users_image img {
  height: 100%;
  width: 100%;
}

.single_request {
  display: grid;
  grid-template-columns: 50% 1fr;
  align-items: center;
  margin: 1rem 0rem;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
  padding: 0rem;
}

.request_user {
  display: flex;
  align-items: center;
}

.request_user h5 {
  margin: 0px;
}

.request_buttons {
  display: flex;
  flex-direction: row-reverse;
}

.request_buttons button {
  margin: 0.5rem;
  border: none;
  color: #fff;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: 5px;
}

.decline {
  background-color: #f44336;
}

.accept {
  background-color: #004d40;
}

.request_header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.5rem;
  background-color: #abb8b8;
}

.request_header button {
  align-items: center;
  appearance: none;
  background-color: #abb8b8;
  /* border-radius: 24px; */
  border-style: none;
  /* box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0; */
  box-sizing: border-box;
  color: #004d40;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-weight: bold;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 0.5rem 0.5rem;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.request_header h5 {
  margin: 0rem;
  display: flex;
  align-items: center;
}

.request_header div {
  display: flex;
  flex-direction: row-reverse;
}

.find_friends {
  margin-top: 3rem;
}

@media screen and (max-width: 1200px) {
  .requests {
    padding: 4rem 0.2rem 2rem;
  }

  .users_image {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
  }

  .single_request {
    display: grid;
    grid-template-columns: 1fr 30%;
    align-items: center;
    margin: 0.5rem;
    /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
    padding: 0.2rem;
  }

  .request_user {
    display: grid;
    grid-template-columns: 14% 1fr;
  }

  .request_user h5 {
    font-size: medium;
  }

  .request_buttons {
    display: flex;
    flex-direction: row-reverse;
  }

  .request_buttons button {
    margin: 0.5rem;
    border: none;
    color: #fff;
    padding: 0.1rem;
    font-weight: bold;
    border-radius: 5px;
  }
}
