.admin_home {
  padding: 2rem;
}

.admin_home_banner_img {
  height: 6rem;
  width: 6rem;
  border-radius: 3rem;
}

.admin_home_banner_img img {
  height: 100%;
  width: 100%;
}

.admin_home_banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overview_box {
  padding: 2rem;
  height: fit-content;
}

.overview_box h3 {
  text-align: center;
}

.overview_box_piechart {
  height: 50%;
}

.overview_box_body li {
  list-style: none;
  display: flex;
  align-items: center;
}

.admin_home_body {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
}

.overview_box_body ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .admin_home {
    padding: 0rem;
  }

  .admin_home_banner_img {
    height: 4rem;
    width: 4rem;
    border-radius: 3rem;
  }

  .admin_home_banner_img img {
    height: 100%;
    width: 100%;
  }

  .admin_home_banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }

  .admin_home_banner h1 {
    text-align: center;
  }

  .overview_box {
    /* box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px; */
    padding: 0rem;
  }

  .overview_box h3 {
    text-align: center;
  }

  .overview_box_piechart {
    height: 65%;
  }

  .overview_box_body {
    padding-top: 1rem;
  }

  .overview_box_body li {
    list-style: none;
    display: flex;
    align-items: center;
    font-size: large;
  }

  .admin_home_body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-gap: 2rem;
    width: 100%;
  }

  .overview_box_body ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
