.pharmacy_banner {
  height: 16rem;
  width: 100%;
  background-color: var(--text);
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.heading_cont {
  height: 22rem;
}

.pharmacy_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 16rem;
  position: relative;
  z-index: 3;
  color: white;
}

.pharmacy_title h1 {
  font-weight: bolder;
  font-size: 2.5rem;
}

.pharmacy_logo {
  height: 10rem;
  width: 10rem;
  border-radius: 5rem;
  background-color: white;
  position: relative;
  top: -6rem;
  left: 5rem;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  overflow: hidden;
}

.pharmacy_logo img {
  width: 100%;
  height: 100%;
}

.pharmacy_menu {
  position: relative;
  top: -8rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0rem 10rem;
}

.pharmacy_menu div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pharmacy_menu button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: var(--text);
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: larger;
  font-weight: bolder;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.pharmacy_menu button:hover {
  background: var(--text);
  color: #f6f9fe;
}

.inventory_list {
  padding-bottom: 3rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inventory {
  overflow-x: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inventory table td,
th {
  padding: 0.5rem 1rem;
}

.inventory tr:nth-child(even) {
  background-color: #e0f2f1;
}

.add_product {
  display: flex;
  justify-content: center;
}

.addform input {
  width: 100%;
  outline: var(--text);
}

.product_name input {
  border: none;
  border-bottom: var(--text) solid 1px;
  outline: none;
  font-size: x-large;
  font-weight: bold;
}

.product_name input:focus {
  border: none;
  border-bottom: var(--text) solid 1px;
  outline: none;
  font-size: x-large;
  font-weight: bold;
  background-color: #e0f2f1;
}

.pharm_logo {
  height: 5rem;
  width: 5rem;
  border-radius: 2.5rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  overflow: hidden;
  margin-bottom: 1rem;
}

.pharm_logo img {
  width: 100%;
  height: 100%;
}

.pharm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  /* width: fit-content;
  height: fit-content; */
  padding: 2rem;
  margin: 1rem;
}

.pharmacy_login {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  padding: 0.5rem;
  width: 100vw;
}

.login_container {
  width: 100vw;
  padding: 1rem;
  text-align: center;
}

.pharmacy_form textarea {
  width: 100%;
  border: none;
  border-bottom: #004d40 solid 1px;
  outline: none;
}

.pharmacy_form textarea:focus {
  background-color: #e0f2f1;
}

.pharmacy_form input {
  width: 95%;
  margin: 0.5rem;
  border: none;
  border-bottom: #004d40 solid 1px;
  outline: none;
}

.pharmacy_form input:focus {
  background-color: #e0f2f1;
}

.product_gallery {
  padding: 2rem 8rem;
}

.gallery_heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery_heading h1 {
  text-transform: uppercase;
}

.gallery_products {
  margin-top: 2rem;
  padding: 0rem 3rem;
}

.drug_class {
  text-transform: uppercase;
}

.drug_class_heading {
  background-color: var(--text);
  font-weight: bolder;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  padding: 0.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drug_class_heading h3 {
  width: 95%;
  color: white;
  margin: 0rem;
}

.show_list {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 1rem;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: var(--text);
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: larger;
  font-weight: bolder;
  height: 2rem;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 2rem;
  will-change: transform, opacity;
  z-index: 0;
}

.drug_class ul {
  margin-left: 0rem;
  margin-top: 0rem;
}

.drug {
  margin-bottom: 2rem;
}

.drug h5 {
  font-weight: bolder;
}

.brand {
  display: flex;
  padding-left: 1rem;
}

.brand li {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--text);
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: large;
  font-weight: bold;
  height: 30px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  margin: 0rem 0.3rem;
  max-width: 100%;
  overflow: visible;
  padding: 0px 10px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.gallery_logo {
  height: 6rem;
  width: 6rem;
  border-radius: 4rem;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  overflow: hidden;
}

.gallery_logo img {
  width: 100%;
  height: 100%;
}

.invent_menu button {
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-radius: 24px;
  border-style: none;
  box-sizing: border-box;
  color: var(--text);
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: large;
  font-weight: bold;
  height: 30px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  margin: 0rem 0.3rem;
  max-width: 100%;
  overflow: visible;
  padding: 0px 10px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.invent_menu {
  padding: 2rem 3rem 0rem;
}

.transaction_table table {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.transaction_table tr:nth-child(even) {
  background-color: #e0f2f1;
  text-transform: capitalize;
}

@media screen and (max-width: 1200px) {
  .pharmacy_banner {
    height: 16rem;
    width: 100%;
    background-color: var(--text);
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
      rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }

  .heading_cont {
    height: 22rem;
  }

  .pharmacy_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 16rem;
    position: relative;
    z-index: 3;
    color: white;
    text-align: center;
  }

  .pharmacy_title h1 {
    font-weight: bolder;
    font-size: 2rem;
  }

  .pharmacy_logo {
    height: 5rem;
    width: 5rem;
    border-radius: 3rem;
    background-color: white;
    position: relative;
    top: -3rem;
    left: 1rem;
    z-index: 5;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    overflow: hidden;
  }

  .pharmacy_logo img {
    width: 100%;
    height: 100%;
  }

  .pharmacy_menu {
    position: relative;
    top: -3rem;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 1rem;
  }

  .pharmacy_menu div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pharmacy_menu button {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
      rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
    box-sizing: border-box;
    color: var(--text);
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-size: larger;
    font-weight: bolder;
    height: 48px;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: max-content;
    will-change: transform, opacity;
    z-index: 0;
  }

  .pharmacy_menu button:hover {
    background: var(--text);
    color: #f6f9fe;
  }

  .inventory_list {
    padding-top: 1rem;
    padding-bottom: 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .inventory {
    overflow-x: scroll;
    display: block;
  }

  .inventory table td,
  th {
    padding: 0.5rem 1rem;
  }

  .inventory_list table {
    font-size: small;
  }

  .add_product {
    display: flex;
    justify-content: center;
  }

  .addform input {
    width: 100%;
    outline: var(--text);
  }

  .product_name input {
    border: none;
    border-bottom: var(--text) solid 1px;
    outline: none;
    font-size: x-large;
    font-weight: bold;
  }

  .pharm_logo {
    height: 5rem;
    width: 5rem;
    border-radius: 2.5rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    overflow: hidden;
    margin-bottom: 1rem;
  }

  .pharm_logo img {
    width: 100%;
    height: 100%;
  }

  .pharm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    width: fit-content;
    height: fit-content;
    padding: 1rem 4rem;
    margin: 0.5rem;
  }

  .pharmacy_login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0rem 1rem;
  }

  .login_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    height: 100vh;
    width: 100vw;
    padding: 1rem;
  }

  .pharmacy_form textarea {
    width: 100%;
    border: none;
    border-bottom: #004d40 solid 1px;
    outline: none;
  }

  .pharmacy_form textarea:focus {
    background-color: #e0f2f1;
  }

  .pharmacy_form input {
    width: 95%;
    margin: 0.5rem;
    border: none;
    border-bottom: #004d40 solid 1px;
    outline: none;
  }

  .pharmacy_form input:focus {
    background-color: #e0f2f1;
  }

  .product_gallery {
    padding: 1rem;
  }

  .gallery_heading {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .gallery_heading h1 {
    text-transform: uppercase;
  }

  .gallery_products {
    margin-top: 2rem;
    padding: 0rem;
  }

  .drug_class {
    text-transform: uppercase;
  }

  .drug_class_heading {
    background-color: var(--text);
    font-weight: bolder;
    box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset,
      rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
      rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset,
      rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
    padding: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .drug_class_heading h3 {
    width: 95%;
    color: white;
  }

  .show_list {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 1rem;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
      rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
    box-sizing: border-box;
    color: var(--text);
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-size: larger;
    font-weight: bolder;
    height: 2rem;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 2rem;
    will-change: transform, opacity;
    z-index: 0;
  }

  .drug_class ul {
    margin-left: 0rem;
    margin-top: 1rem;
  }

  .drug {
    margin-bottom: 3rem;
  }

  .drug h5 {
    font-weight: bold;
  }

  .brands {
    display: flex;
  }

  .brands li {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: none;
    box-sizing: border-box;
    color: var(--text);
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-size: large;
    font-weight: bold;
    height: max-content;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: normal;
    margin: 0.2rem;
    max-width: 100%;
    overflow: visible;
    padding: 0px 10px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: max-content;
    will-change: transform, opacity;
    z-index: 0;
  }

  .brand{
    padding: 0px;
    display: flex;
    flex-wrap: wrap;
  }

  .gallery_logo {
    height: 6rem;
    width: 6rem;
    border-radius: 3rem;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    overflow: hidden;
  }

  .gallery_logo img {
    width: 100%;
    height: 100%;
  }

  .invent_menu button {
    align-items: center;
    appearance: none;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-sizing: border-box;
    color: var(--text);
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-size: large;
    font-weight: bold;
    height: 30px;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: normal;
    margin: 0rem 0.3rem;
    max-width: 100%;
    overflow: visible;
    padding: 0px 10px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: max-content;
    will-change: transform, opacity;
    z-index: 0;
  }

  .invent_menu {
    padding: 1rem;
  }

  .transaction_table table {
    display: block;
  }
  .product_name {
    width: 80%;
    display: flex;
    justify-content: center;
  }
}
