.profile {
  padding: 10rem 15rem 1rem;
}

.profile_image {
  width: 10rem;
  height: 10rem;
  border-radius: 5rem;
  background-color: #eceff1;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  margin: 0.5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.profile_image img {
  width: 100%;
  height: 100%;
}

.profile_banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 7rem;
}

.edit_image {
  position: relative;
  top: 3.5rem;
  left: 3rem;
  padding: 0.4rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 2rem;
  background-color: #d5d1d1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.full_name {
  position: relative;
  top: 6rem;
  display: flex;
  align-items: center;
}

.profile_banner h3 {
  text-transform: capitalize;
  margin: 0.3rem;
}

.profile_navigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
}

.profile_navigation div {
  display: flex;
  justify-content: center;
}

.profile_button {
  border: none;
  background-color: #fff;
  font-weight: 600;
  font-size: large;
  color: #004d40;
}

.profile_button_select {
  border: none;
  background-color: #fff;
  font-weight: bolder;
  font-size: large;
  color: #004d40;
  border-bottom: #004d40 3px solid;
}

.profile_details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 0rem auto 2rem;
}

.profile_details p {
  display: flex;
  justify-content: center;
  width: 100%;
}

.p {
  text-transform: capitalize;
}

.profile_details b {
  margin-right: 0.3rem;
}

.pharmacy_button {
  position: relative;
  left: 25rem;
}

.pharmacy_button button {
  align-items: center;
  appearance: none;
  background-color: var(--text);
  border-radius: 10px 0px 10px 0px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: larger;
  font-weight: bolder;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

.request_button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.request_button button {
  align-items: center;
  appearance: none;
  background-color: var(--text);
  border-radius: 10px 0px 10px 0px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
    rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-size: larger;
  font-weight: bolder;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.25px;
  line-height: normal;
  margin: 0rem 1rem;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  will-change: transform, opacity;
  z-index: 0;
}

@media screen and (max-width: 1200px) {
  .profile {
    padding: 5rem 0rem 1rem;
  }

  .profile_image {
    width: 8rem;
    height: 8rem;
    border-radius: 4rem;
    background-color: #eceff1;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    margin: 0.5rem;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }

  .profile_image img {
    width: 100%;
    height: 100%;
  }

  .profile_banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 7rem;
  }

  .edit_image {
    position: relative;
    top: 2.5rem;
    left: 2.5rem;
    padding: 0.4rem;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 2rem;
    background-color: #d5d1d1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .full_name {
    position: relative;
    top: 3rem;
    display: flex;
    align-items: center;
  }

  .profile_banner h3 {
    text-transform: capitalize;
    margin: 0.3rem;
  }

  .profile_navigation {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2rem;
  }

  .profile_navigation div {
    display: flex;
    justify-content: center;
  }

  .profile_button {
    border: none;
    background-color: #fff;
    font-weight: 600;
    font-size: large;
    color: #004d40;
  }

  .profile_button_select {
    border: none;
    background-color: #fff;
    font-weight: bolder;
    font-size: large;
    color: #004d40;
    border-bottom: #004d40 3px solid;
  }

  .profile_details {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-bottom: 2rem;
  }

  .profile_details p {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .p {
    text-transform: capitalize;
  }

  .profile_details b {
    margin-right: 0.3rem;
  }

  .pharmacy_button {
    position: relative;
    left: 0rem;
    top: 11rem;
  }

  .pharmacy_button button {
    align-items: center;
    appearance: none;
    background-color: var(--text);
    border-radius: 10px 0px 10px 0px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px,
      rgba(0, 0, 0, 0.14) 0 6px 10px 0, rgba(0, 0, 0, 0.12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    fill: currentcolor;
    font-size: larger;
    font-weight: bolder;
    height: 48px;
    justify-content: center;
    letter-spacing: 0.25px;
    line-height: normal;
    max-width: 100%;
    overflow: visible;
    padding: 2px 24px;
    position: relative;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1),
      opacity 15ms linear 30ms, transform 270ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: max-content;
    will-change: transform, opacity;
    z-index: 0;
  }

  .space {
    margin-bottom: 5rem;
  }
}
